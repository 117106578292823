.sidebar {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    background: white;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.sidebar::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.form-control-label {
    display: inline-block;
    width: 50%;
    word-wrap: break-word;
}

.MuiFormControlLabel-root{
    display: flex !important;
}

.feedback-button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 450;
    border-radius: 3px;
    padding: 12px 24px;
    border: 0;
    color: #fff;
    background: #ff5000;
    line-height: 1.15;
    font-size: 18px;
    transform: rotate(-90deg);
    transform-origin: left bottom;
  }
  
  .feedback-button:hover {
    transition: all 0.1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px #32f3c3;
  }