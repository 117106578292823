.number {
  font-size: 50px !important;
}

.delta {
  visibility: hidden !important;
}

.MuiButtonBase-root-MuiTab-root {
  background-color: gainsboro;
}

.MuiTab-root.Mui-selected {
  background: #2989e3 !important;
  color: white !important;
}

.MuiTabs-root {
  background-color: white !important;
}

.statusText {
  font-weight: "500";
  font-size: "25px";
  position: "absolute";
  color: "black";
  top: "35%";
  left: "43%";
  z-index: "1";
  font-family: "PFDinTextCompProMedium";
}
