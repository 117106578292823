.container {
  display: flex;
  height: 100vh;
}

.left-side {
  flex: 15%;
  overflow-y: auto;
  background-color: #fff; /* Optional background color for left side */
}

.left-side::-webkit-scrollbar {
  display: none;
}

.right-side {
  flex: 75%;
  background-color: #e0e0e0; /* Optional background color for right side */
}

.right-side::-webkit-scrollbar {
  display: none;
}

.gpt-section {
  overflow: auto;
  max-height: 400px;
  scrollbar-width: none;
}

.gpt-section::-webkit-scrollbar {
  display: none;
}

.box {
  border-top-left-radius: 37px 140px;
  border-top-right-radius: 23px 130px;
  border-bottom-left-radius: 110px 19px;
  border-bottom-right-radius: 120px 24px;

  border: solid 3px #6e7491;
  padding: 40px 60px;
  max-width: 800px;
  width: 70%;
  font-size: 17px;
  line-height: 28px;
  transform: rotate(-1deg);
  box-shadow: 3px 15px 8px -10px rgba(0, 0, 0, 0.3);
}

.title {
  padding-left: 10px;
  font-weight: bold !important;
}

.text {
  padding-left: 10px !important;
}

.link2text {
  text-decoration: underline;
  color: blue;
}

.feedback-button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 450;
  border-radius: 3px;
  padding: 12px 24px;
  border: 0;
  color: #fff;
  background: #ff5000;
  line-height: 1.15;
  font-size: 18px;
  transform: rotate(-90deg);
  transform-origin: left bottom;
}

.feedback-button:hover {
  transition: all 0.1s ease;
  box-shadow: 0 0 0 0 #fff, 0 0 0 3px #32f3c3;
}
