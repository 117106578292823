:root {
  --orange-color: #ff9800;
  --blue-color: #0288d1;
  --purple-color: #7b1fa2;
  --green-color: #558b2f;
}

a[title="Report errors in the road map or imagery to Google"] {
  display: none !important;
}

/*
   * Property styles in unhighlighted state.
   */
.property {
  align-items: center;
  background-color: "rgba(33, 150, 243, 1)";
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 60px;
  justify-content: center;
  pointer-events: all !important;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 60px;
}

.property::after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #ffffff;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
  background-color: transparent;
}

.customMarker {
  border: "2px solid black !important";
  border-radius: "50% !important";
  height: "50px";
  width: "50px";
}

.image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  aspect-ratio: 16 / 9;
}

.property .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.property .icon svg {
  height: 20px;
  width: auto;
}

.property .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.property .expertise {
  color: #000000;
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: "PFDinTextCompProMedium";
}

.property .site {
  color: #000000;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "PFDinTextCompProMedium";
}

.property .name {
  color: #000000;
  font-size: 17px;
  font-family: "PFDinTextCompProMedium";
}

.property .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property .features > div {
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/*
   * Property styles in highlighted state.
   */
.property.highlight {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: 120px;
  padding: 8px 15px;
  width: auto;
}

.property.highlight::after {
  border-top: 5px solid #ffffff;
}

.property.highlight .details {
  display: flex;
}

.property.highlight .customMarker .image {
  width: 70px;
  height: 70px;
}

/*
   * House icon colors.
   */
.property.highlight:has(.blue) .icon {
  color: var(--blue-color);
}

.property:not(.highlight):has(.blue) {
  background-color: var(--blue-color);
}

.property:not(.highlight):has(.blue)::after {
  border-top: 9px solid var(--blue-color);
}

/*
   * Building icon colors.
   */
.property.highlight:has(.orange) .icon {
  color: var(--orange-color);
}

.property:not(.highlight):has(.orange) {
  background-color: var(--orange-color);
}

.property:not(.highlight):has(.orange)::after {
  border-top: 9px solid var(--orange-color);
}

/*
   * Warehouse icon colors.
   */
.property.highlight:has(.green) .icon {
  color: var(--green-color);
}

.property:not(.highlight):has(.green) {
  background-color: var(--green-color);
}

.property:not(.highlight):has(.green)::after {
  border-top: 9px solid var(--green-color);
}

/*
   * Shop icon colors.
   */
.property.highlight:has(.purple) .icon {
  color: var(--purple-color);
}

.property:not(.highlight):has(.purple) {
  background-color: var(--purple-color);
}

.property:not(.highlight):has(.purple)::after {
  border-top: 9px solid var(--purple-color);
}

@keyframes drop {
  0% {
    transform: translateY(-200px) scaleY(0.9);
    opacity: 0;
  }
  5% {
    opacity: 0.7;
  }
  50% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
  65% {
    transform: translateY(-17px) scaleY(0.9);
    opacity: 1;
  }
  75% {
    transform: translateY(-22px) scaleY(0.9);
    opacity: 1;
  }
  100% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
}
.drop {
  animation: drop 0.3s linear forwards var(--delay-time);
}
