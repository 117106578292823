.youtube-video-image + figcaption {
  font-size: 15px !important;
}

.playlist-gallery-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  grid-gap: 0 1rem;
}

.youtube-video-image + figcaption {
  font-weight: 100;
  font-size: 16px !important;
  padding-bottom: 20px;
  color: black !important;
  font-family: "Gill Sans MT";
}

.css-1g0p095-MuiTypography-root {
  color: black !important;
}

.bluegap-ai-btn {
  text-decoration: none;
  color: #000;
  margin: auto;
  width: 80px;
  display: inline-block;
  line-height: 40px;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #fff;
  border: 5px solid #000;
  box-shadow: 1px 1px 0, 2px 2px 0, 3px 3px 0, 4px 4px 0, 5px 5px 0;
  position: relative;
  cursor: pointer;
}

.bluegap-ai-btn:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #fff;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.bluegap-ai-btn:hover {
  background-color: transparent;
}
.bluegap-ai-btn:hover:after {
  background-color: #2896fd;
}

.bluegap-ai-btn:active {
  top: 5px;
  left: 5px;
  box-shadow: 0 0 0 0;
}

/* .playlist-gallery-div {
    display: flex;
    overflow-x: auto;
}

.playlist-gallery-div > * {
    flex: 0 0 auto;
    margin-right: 1rem;
    width: 325px;
    margin-bottom: 0px !important;
  }

.youtube-video-image + figcaption {
    font-weight: 400;
    font-size: 13px;
    padding-bottom: 0px;
} */

.grid {
  list-style: none;
  padding: 0;
  margin-left: 0;
  align-items: flex-start;
}

.grid img {
  max-width: 100%;
}

.feedback-btn {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 3px;
  padding: 12px 24px;
  border: 0;
  color: #fff;
  background: #ff5000;
  line-height: 1.15;
  font-size: 16px;
  :hover {
    transition: all 0.1s ease;
    box-shadow: 0 0 0 0 #fff, 0 0 0 3px #1de9b6;
  }
}

.feedback-button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 450;
  border-radius: 3px;
  padding: 12px 24px;
  border: 0;
  color: #fff;
  background: #ff5000;
  line-height: 1.15;
  font-size: 18px;
  transform: rotate(-90deg);
  transform-origin: left bottom;
}

.feedback-button:hover {
  transition: all 0.1s ease;
  box-shadow: 0 0 0 0 #fff, 0 0 0 3px #32f3c3;
}
