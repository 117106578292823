@font-face {
  font-family: "PFDinTextCompProMedium";
  src: url("assets/fonts/pfdintextcomppro-medium-webfont.eot");
  src: url("assets/fonts/pfdintextcomppro-medium-webfont.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/pfdintextcomppro-medium-webfont.woff") format("woff"),
    url("assets/fonts/pfdintextcomppro-medium-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.gm-style iframe + div {
  border: none !important;
}